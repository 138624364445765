import React,{ useCallback, useEffect,useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import CountriesDrawer from "./country.drawer";
import CountriesGlobalDrawer from "./country.global.drawer";
import CountriesTable from "./country.table";
import { countryRelations as relations } from "services/relations";
import * as CountryAction from "redux/country/Actions";
import {  useDispatch,useSelector } from "react-redux";

function Country() {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [globalDrawer, setGlobalDrawer] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editGlobal, setEditGlobal] = useState(false);
  const [remove, setRemove] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const globalRecord = useSelector((state) => state.country.globalRecord);

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const where = "";
    // const includeOverview="includeOverview=true";
    const includeOverview="";
    const onlyActive="onlyActive=false"
    
    const query = createQueryParams(limit, offset, text, relations,where,includeOverview,onlyActive,orderBy);
    return { limit, offset, text, relations, query };
  };

const fetchGlobalContent = useCallback(async () => {
  try {
    dispatch(CountryAction.loading({ getGlobal: true }));
    await dispatch(CountryAction.getGlobalContent("where[type]=country")); 
  } catch (error) {
    console.error("Error fetching global content:", error);
  } finally {
    dispatch(CountryAction.loading({ getGlobal: false }));
  }
}, [dispatch]);

  useEffect(() => {
    fetchGlobalContent();
  }, [fetchGlobalContent,globalDrawer]);

  useEffect(() => {
    setEditGlobal(globalRecord.count>0?true:false)
  }, [globalRecord]);

  const commonProps = {
    remove,
    setRemove,
    edit,
    setEdit,
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
    globalDrawer,
    setGlobalDrawer,
    editGlobal,
    setEditGlobal,
    orderBy,
    setOrderBy
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Countries</h1>
        <div className="buttons-on-title mx-5" >
        <button onClick={() => setDrawer(true)} className="btn btn-theme">  Add New Country </button>
          {globalRecord && globalRecord.count > 0 ? (
            <button
              onClick={() => setGlobalDrawer(true)}
              className="btn mx-2 btn-theme"
            >
              Edit Global Content
            </button>
          ) : (
            <button
              onClick={() => setGlobalDrawer(true)}
              className="btn mx-2 btn-theme"
            >
              Add Global Content
            </button>
          )}
        </div>
      </div>
      <CountriesTable {...commonProps} />
      <CountriesDrawer {...commonProps} />
      <CountriesGlobalDrawer {...commonProps} />
    </div>
  );
}

export default Country;
