import React,{ useCallback, useEffect,useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import SpecialitiesDrawer from "./speciality.drawer";
import SpecialitiesTable from "./speciality.table";
import { specialityRelations as relations } from "services/relations";
import SpecialityGlobalDrawer from "./speciality.global.drawer";
import {  useDispatch,useSelector } from "react-redux";
import * as CountryAction from "redux/country/Actions";


function Speciality() {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [globalDrawer, setGlobalDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [editGlobal, setEditGlobal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const globalRecord = useSelector((state) => state.country.globalRecord);


  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const query = createQueryParams(limit, offset, text, relations,orderBy);
    return { limit, offset, text, relations, query };
  };

  
const fetchGlobalContent = useCallback(async () => {
  try {
    dispatch(CountryAction.loading({ getGlobal: true }));
    await dispatch(CountryAction.getGlobalContent("where[type]=speciality")); 
  } catch (error) {
    console.error("Error fetching global content:", error);
  } finally {
    dispatch(CountryAction.loading({ getGlobal: false }));
  }
}, [dispatch]);

  useEffect(() => {
    fetchGlobalContent();
  }, [fetchGlobalContent,globalDrawer]);

  useEffect(() => {
    setEditGlobal(globalRecord.count>0?true:false)
  }, [globalRecord]);


  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    globalDrawer,
    setGlobalDrawer,
    editGlobal,
    setEditGlobal,
    orderBy,
    setOrderBy
  };
  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Specialities</h1>
        <div className="buttons-on-title  mx-5">
          <button onClick={() => setDrawer(true)} className="btn btn-theme"> Add New Speciality </button>
          {globalRecord && globalRecord.count > 0 ? (
            <button
              onClick={() => setGlobalDrawer(true)}
              className="btn mx-2 btn-theme"
            >
              Edit Global Content
            </button>
          ) : (
            <button
              onClick={() => setGlobalDrawer(true)}
              className="btn mx-2 btn-theme"
            >
              Add Global Content
            </button>
          )}
        </div>
      </div>

      <SpecialitiesTable {...commonProps} />
      <SpecialitiesDrawer {...commonProps} />
      <SpecialityGlobalDrawer {...commonProps} />

    </div>
  );
}

export default Speciality;
