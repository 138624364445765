import { Pagination, Table  } from "antd";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/content/Actions";
import * as CountryAction from "redux/country/Actions";
import * as specialitiesAction from "redux/specialities/Actions";
import Select from "react-select";
import { treatmentSlice } from "redux/treatment/Reducer";
import { DiseaseSlice } from "redux/diseases/Reducer";
import {
  DeleteButton,
  EditButton} from "components/button/Buttons";

function FilterContentTable(props) {
  const {
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    setEditRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    getPagination,
    flag,
    setFlag
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  let offset = (pageNumber - 1) * pageSize;
  offset = offset > 1 ? offset : 0;
  const {
    user,
    loading,
    specialityRecords,
    countryRecords,
  } = useSelector(
    (state) => ({
      user: state.auth.user || null,
      loading: state.content?.loading,
      specialityRecords: state.speciality?.records,
      countryRecords: state.country?.records || [],      
    }),
    shallowEqual
  );
  
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryNameNotSlug, setCountryNameNotSlug] = useState("");
  const [specility, setSpecility] = useState("");
  const [specilityName, setSpecilityName] = useState("");
  const [treatment, setTreatment] = useState("");
  const [treatmentName, setTeatmentName] = useState("");
  const [disease, setDisease] = useState("");
  const [countryConditon, setCountryConditon] = useState("");
  const [treatmentRecords, setTreatmentRecords] = useState([]);
  const [diseaseRecords, setDiseaseRecords] = useState([]);
  const [recordsTable, setRecordsTable] = useState([]);
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState("");


  function getCountMessage(specialitiesSelected, treatmentsSelected, diseasesSelected, countrySelected, countryNameNotSlug,newCount) {
    if (specialitiesSelected && treatmentsSelected && diseasesSelected) {
      if (countrySelected) {
        return `Diseases in ${countryNameNotSlug}: ${newCount}`;
      } else {
        return `Diseases: ${newCount}`;
      }
    }
  
    if (specialitiesSelected && treatmentsSelected) {
      if (countrySelected) {
        return `Diseases in ${countryNameNotSlug}: ${newCount}`;
      } else {
        return `Diseases: ${newCount}`;
      }
    }
  
    if (specialitiesSelected && !treatmentsSelected) {
      if (countrySelected) {
        return `Treatments in ${countryNameNotSlug}: ${newCount}`;
      } else {
        return `Treatments: ${newCount}`;
      }
    }
  
    return ''; 
  }

  
  const fetchTreatmentData = async () => {
    try {
      const data = await dispatch(actions.contentFilters({ query: `specialityId=${specility}${countryConditon}&limit=1000000000&offset=${offset}&relations[]=slug&select[slug][slug]=true` }));
      setTreatmentRecords(data.data);
      offset===0 && setCount(data?.data?.length)
      offset===0 && setMessage(getCountMessage(specility, treatment, disease, country, countryNameNotSlug, data?.data?.length)); 
      dispatch(treatmentSlice.actions.getAll({ records: data.data, count: data?.data?.length}));

      const dataTable = await dispatch(actions.contentFilters({ query: `specialityId=${specility}${countryConditon}&limit=${pageSize}&offset=${offset}&relations[]=slug&select[slug][slug]=true` }));
      setRecordsTable(dataTable.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchDiseaseData = async () => {
    try {
      const data = await dispatch(actions.contentFilters({ query: `specialityId=${specility}&treatmentId=${treatment}${countryConditon}&limit=1000000000&offset=0&relations[]=slug&select[slug][slug]=true` }));
      setDiseaseRecords(data.data);
      offset===0 && setCount(data?.data?.length)
      offset===0 && setMessage(getCountMessage(specility, treatment, disease, country, countryNameNotSlug, data?.data?.length)); 

      dispatch(DiseaseSlice.actions.getAll({ records: data.data, count: data?.data?.length}));

      const dataTable = await dispatch(actions.contentFilters({ query: `specialityId=${specility}&treatmentId=${treatment}${countryConditon}&limit=${pageSize}&offset=${offset}&relations[]=slug&select[slug][slug]=true` }));
      setRecordsTable(dataTable.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchTreatmentDataTable = async () => {
    try {
      const dataTable = await dispatch(actions.contentFilters({ query: `specialityId=${specility}${countryConditon}&limit=${pageSize}&offset=${offset}&relations[]=slug&select[slug][slug]=true` }));
      offset===0 && setCount(dataTable?.data?.length)
      offset===0 && setMessage(getCountMessage(specility, treatment, disease, country, countryNameNotSlug, dataTable?.data?.length)); 

      setRecordsTable(dataTable.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchDiseaseDataTable = async () => {
    try {
      const dataTable = await dispatch(actions.contentFilters({ query: `treatmentId=${treatment}${countryConditon}&limit=${pageSize}&offset=${offset}&relations[]=slug&select[slug][slug]=true` }));
      offset===0 && setCount(dataTable?.data?.length)
      offset===0 && setMessage(getCountMessage(specility, treatment, disease, country, countryNameNotSlug, dataTable?.data?.length)); 

      setRecordsTable(dataTable.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchSingleDiseaseDataTable = async () => {
    try {
      const arr = [];
      const dataTable = await dispatch(actions.contentFilters({ query: `specialityId=${specility}&treatmentId=${treatment}&diseaseId=${disease}${countryConditon}&limit=${pageSize}&offset=${offset}&relations[]=slug&select[slug][slug]=true` }));
      setCount(1)
      offset===0 && setMessage(getCountMessage(specility, treatment, disease, country, countryNameNotSlug, 1)); 
      arr.push(dataTable.data);
      setRecordsTable((arr));
    } catch (error) {
      console.error("Error is:", error);
    }
  };

  
  useEffect(
    () => {
    
      if(specility && treatment && !disease){
        fetchDiseaseDataTable();
      }
      else if( specility && !treatment  && !disease){
        fetchTreatmentDataTable();
      }
     
    },
    // eslint-disable-next-line
    [pageSize,pageNumber]
  );

  useEffect(
    () => {
      let countryQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&relations[]=slug&select[slug][slug]=true`;
      let specilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&relations[]=slug&select[slug][slug]=true`;
      dispatch(CountryAction.getAll({ query: countryQuery }));
      dispatch(specialitiesAction.getAll({ query: specilityQuery }));
      if(specility && treatment && disease){
        setRecordsTable(null)
        fetchSingleDiseaseDataTable();
      }
      
      else if(specility && treatment && !disease){
        fetchDiseaseData();
      }
      else if( specility && !treatment  && !disease){
        fetchTreatmentData();
      }
      else{
        setRecordsTable(null);
        setCount(0);
        setMessage('');
      }
    },
    // eslint-disable-next-line
    [specility,treatment,disease,country]
  );


  useEffect(() => {
    if (flag === true) {
        // call the api only when flag is true
        let countryQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&relations[]=slug&select[slug][slug]=true`;
        let specilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&relations[]=slug&select[slug][slug]=true`;

        dispatch(CountryAction.getAll({ query: countryQuery }));
        dispatch(specialitiesAction.getAll({ query: specilityQuery }));

        if (specility && treatment && disease) {
            setRecordsTable(null);
            fetchSingleDiseaseDataTable();
        } else if (specility && treatment && !disease) {
            fetchDiseaseData();
        } else if (specility && !treatment && !disease) {
            fetchTreatmentData();
        }
        setFlag(false)
    }
},
// eslint-disable-next-line 
[flag, specility, treatment, disease, country, drawer]);

  
  const countryOptions = countryRecords?.map(
    ({ id: value, name_en,slug }, index) => ({
      value,
      label: name_en,
      slug: slug?.slug,
      index,
    })
  );

  const specialitiesOptions = specialityRecords?.map(
    ({ id: value, name_en,slug }, index) => ({
      value,
      label: name_en,
      slug: slug?.slug,
      index,
    })
  );

  const treatmentOptions =  treatmentRecords?.map(
    ({ id: value, name_en,slug }, index) => ({
      value,
      label: name_en,
      slug: slug?.slug,
      index,
    })
  );
  const diseaseOptions = diseaseRecords?.map(
    ({ id: value, name_en,slug }, index) => ({
      value,
      label: name_en,
      slug: slug?.slug,
      index,
    })
  );
  const handleDelete = () => {
    dispatch(actions.removeFilter(deleteRecord?.content?.id, setDeleteRecord, pagination,setFlag));
  };


  const resetCountry = () => {
    setCountry(null);
    setCountryName('');
    setCountryNameNotSlug('');
    setCountryConditon('')
  };

  const resetDisease = () => {
    setDisease(null);
  };
  
  const resetTreatment = () => {
    setTreatment(null);
    setTeatmentName('')
    resetDisease()

  };
 
  const resetSpecility = () => {
    setSpecility(null);
    resetTreatment()

  };

  const columns = [
    {
      title: (
        <>
          Name<span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (text_en, entity) => {
        return <>{entity?.name_en}</>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text_en, entity) => {
        // Construct the URL based on the given conditions
        let url = "";
       
          url = `${process.env.REACT_APP_BASE_URL_USER}en/clinics/${countryName ? countryName + '/' : '' }${specilityName ? specilityName+'/' :''}${treatmentName ? treatmentName+'/' :''}${entity?.slug?.slug}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              
              {(user.role === "admin" ||
                user.role === "author" ||
                user.role === "editor") && (
                <span className="nav-link mx-1">
                  <EditButton
                    onClick={() => {
                      setEditRecord(entity);
                      setDrawer({"specility":entity.specilityId || specility,"treatment":treatment || entity.treatmentId || entity.id ,"disease":disease || (specility && (treatment ||  entity.treatmentId) && entity.id),"country":country});
                    }}
                  />
                </span>
              )}
              {user.role === "admin" && (
                <span className="nav-link ">
                  <DeleteButton onClick={() => setDeleteRecord(entity)} />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };


  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
       
        <div className="row  " style={{ paddingRight: "50px" }}>
        <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total {message?message:" : "+0}</h3>
            </div>
          </div>
          <div className="col-12 col-md-auto mx-3">
            <span className="drawer_span required">Specilities</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="Specilities"
                value={
                  specialitiesOptions?.find(
                    (option) => option?.value === specility
                  ) || null
                }
                options={specialitiesOptions}
                onChange={(selectedOption) => {
                  resetSpecility();
                  setSpecility(selectedOption ? selectedOption.value : null);
                  setSpecilityName(selectedOption ? selectedOption.slug : null)
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetSpecility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
               <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Treatments</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="tratments"
                value={
                  treatmentOptions?.find(
                    (option) => option?.value === treatment
                  ) || null
                }
                options={specility && treatmentOptions}
                onChange={(selectedOption) => {
                  resetTreatment()
                  setTreatment(selectedOption ? selectedOption.value : null);
                  setTeatmentName(selectedOption ? selectedOption.slug : null);

                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetTreatment}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
               <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Disease</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="diseases"
                value={
                  diseaseOptions?.find((option) => option?.value === disease) ||
                  null
                }
                options={treatment && diseaseOptions}
                onChange={(selectedOption) => {
                  resetDisease()
                  setDisease(selectedOption ? selectedOption.value : null);

                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetDisease}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Country</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="countries"
                value={
                  countryOptions?.find((option) => option?.value === country) ||
                  null
                }
                options={countryOptions}
                onChange={(selectedOption) => {
                  resetCountry()
                  setCountry(selectedOption ? selectedOption.value : null);
                  setCountryName(selectedOption ? selectedOption.slug : null);
                  setCountryNameNotSlug(selectedOption ? selectedOption.label : null);
                  setCountryConditon(`&countryId=${selectedOption ? selectedOption.value : null}`);


                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetCountry}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          {/*  */}
          
          {/*  */}
        </div>
        {(
        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            scroll={{ x: "calc(100vh - 420px)" }}
            pagination={false}
            dataSource={ specility && recordsTable}
            columns={columns}
          />
       
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
          
        </div>
      )}
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default FilterContentTable;
